import axios from 'axios';

export default class TokenService {
  constructor(tokenServiceUrl) {
    this.tokenServiceUrl = tokenServiceUrl;
  }

  async _request() {
    const response = await axios({
      method: 'POST',
      url: this.tokenServiceUrl,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data.jwt;
  }
  /**
   * Executes requests
   */
  async execute() {
    const data = await this._request();
    return data;
  }
}
