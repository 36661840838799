/**
 * trim - Trims a string if it exists, else returns blank string
 *
 * @param {String} str     String to trim spaces from
 * @returns {String}
 */
export const trim = str => (str && str.length ? str.replace(/^\s+|\s+$/gm, '') : '');

/**
 * isNullEmptyOrUndefined - Checks to see if the parameter is null,
 * empty, or undefined.
 *
 * @param {*} str     Item to verify
 * @returns {Boolean}
 */
export const isNullEmptyOrUndefined = obj => {
  return obj === null || typeof obj === 'undefined' || (typeof obj === 'string' && trim(obj) === '');
};

/**
 * ASCII to Unicode (decode Base64 to original data)
 * @param {string} b64
 * @return {string}
 */
export const atou = (b64) => {
  return decodeURIComponent(escape(atob(b64)));
}

/**
 * Unicode to ASCII (encode data to Base64)
 * @param {string} data
 * @return {string}
 */
export const utoa = (data) => {
  return btoa(unescape(encodeURIComponent(data)));
}

export default {
  trim,
  isNullEmptyOrUndefined,
  atou,
  utoa
};
