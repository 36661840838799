import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './notification.scss';

class Notification extends React.Component {
  static propTypes = {
    notification: PropTypes.object,
    open: PropTypes.bool,
    dismiss: PropTypes.func,
    redirectEvent: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.appointmentLinkRef = React.createRef();
    this.state = { shouldBeLabelled: true } ;
  }

  componentDidMount() {
    const focusDelay = 200;
    if (this.appointmentLinkRef.current) {
      setTimeout(() => this.appointmentLinkRef.current.focus(), focusDelay);
    }
  }

  render() {
    const { notification, open, dismiss, redirectEvent } = this.props;
    const titleId = 'gn_notificationTitle';
    const contentId = 'gn_notificationContent';
    return (
      <div
        role="dialog"
        aria-labelledby={titleId}
        aria-describedby={contentId}
        className={classNames('gn-notification', { open: open })}
      >
        <div
          className="notification-svg brandColor"
          dangerouslySetInnerHTML={{ __html: notification.notificationSvgData }}
        />
        <div className="notification-content">
          <h3 id={titleId} className="gn_notificationTitle">{notification.title}</h3>
          <p id={contentId} >{notification.subtitle}</p>
          <a ref={this.appointmentLinkRef} data-testid='appointmentLink' href={notification.context.appointmentDetailsUrl} onClick={redirectEvent}>
            <span>{notification.redirectLinkText}</span>
          </a>
        </div>
        <button className="notification-dismiss" onClick={dismiss}>
          <span>{notification.dismissButtonText}</span>
          <div className="dismiss-svg" dangerouslySetInnerHTML={{ __html: notification.dismissSvgData }} />
        </button>
      </div>
    );
  }
}

export default Notification;
