import ContentService from '../services/contentService';
import KEYS from '../constants/storageKeys';

export default class ContentStore {
  constructor(contentUrl) {
    this._service = new ContentService(contentUrl, KEYS.CONTENT_KEY);
    this.contentList = [];
  }

  async triggerContentListRefresh() {
    const response = await this._service.execute();
    this.contentList = response;
  }
}
