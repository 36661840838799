import { REDIRECT, DISPLAY, DISMISS } from './constants';
import { ANALYTICS_CLICK, ANALYTICS_ALERT } from './constants';

/**
 * handleAnalyticsEvent - generic method to handle all events that need to publish analytics
 * @param {*} eventData the object in the form {eventType: String, notification:object}. the object passed as value
 * for the event key should be the event object passed from the browser event
 */
export const handleAnalyticsEvent = ({ eventType, notification }) => {
  let analyticsNamespace;
  let linkName;
  switch (eventType) {
    case REDIRECT:
      analyticsNamespace = ANALYTICS_CLICK;
      linkName = notification.redirectUrlLink;
      break;
    case DISMISS:
      analyticsNamespace = ANALYTICS_CLICK;
      linkName = 'dismiss';
      break;
    case DISPLAY:
      analyticsNamespace = ANALYTICS_ALERT;
      linkName = notification.redirectUrlLink;
      break;
  }
  try {
    window.gaPublishPostPageData(analyticsNamespace, {
      content: {
        businessUnit: notification.businessUnit || '',
        website: notification.website || '',
        language: notification.language || '',
        alertTitle: notification.title || '',
        alertType: notification.alertType || '',
        alertProgram: notification.alertProgram || '',
        alertRemainingTime: notification.alertRemainingTime || ''
      },
      actions: {
        linkName: linkName
      },
      user: {
        userType: notification.userType || '',
        loginStatus: notification.loginStatus || '',
        siteId: notification.siteId || '',
        clientId: notification.clientId || '',
        groupId: notification.groupId || ''
      }
    });
  } catch (e) {
    console.error(`Analytics not published: ${e}`);
  }
};
