import axios from 'axios';
import TIMINGS from '../constants/timerConstants';

export default class NotificationsService {
  constructor(apiPath) {
    this.apiPath = apiPath;
  }

  async _request(token) {
    const now = Date.now();
    const startTime = new Date(now).toISOString();
    const endTime = new Date(now + TIMINGS.DORMANT_REFRESH_MS).toISOString();
    const response = await axios
      .get(this.apiPath, {
        headers: {
          'X-UHGSV-AUTHORIZATION': `Bearer ${token}`,
          'Authorization': `Bearer ${token}`
        },
        params: {
          startTime: startTime,
          endTime: endTime
        }
      })
      .catch(error => {
        return error.response;
      });
    return response;
  }

  /**
   * Executes requests
   */
  async execute(token) {
    const data = await this._request(token);
    return data;
  }
}
