import '../styles/_main.scss';
import Notification from './components/notification/notification';
import React from 'react';
import ReactDOM from 'react-dom';
import { handleAnalyticsEvent } from './analytics/handleAnalyticsEvent';
import { DISMISS, REDIRECT } from './analytics/constants';
import StateManager from './state/stateManager';
import { isNullEmptyOrUndefined } from './utils/defUtils';

const animationDelayMS = 500;

class GlobalNotifications {
  /**
   * Method exposed to Global Loader to initialize GlobalNotifications
   */
  boot = ({ brand = 'optum', language = 'en', token, tokenServiceUrl, contentBaseUrl, serviceBaseUrl }) => {
    this.brand = brand.toLowerCase();
    this.stateManager = new StateManager(
      this.display,
      this.hide,
      token,
      tokenServiceUrl,
      contentBaseUrl,
      serviceBaseUrl,
      language
    );
    this.stateManager.initialize();
    this.enabled = true;
    this.displayed = false;
    this.hasBeenDismissed = false;
    this.hasRedisplayInterval = false;
    this._createNotificationsNode();
    document.addEventListener('GN_modalEvent', e => this.handleGlobalNavModalEvent(e.detail.expanded));
    return { disable: this.disable, enable: this.enable };
  };
  /**
   * Method exposed to Global Loader to disable GlobalNotifications
   */
  disable = () => {
    this.enabled = false;
    if (this.displayed) {
      this.displayed = false;
      ReactDOM.unmountComponentAtNode(this.notificationRoot);
    }
  };

  /**
   * Method exposed to Global Loader to enable GlobalNotifications
   */
  enable = () => {
    this.stateManager.refreshNotifications();
    this.enabled = true;
  };

  handleGlobalNavModalEvent = isModalExpanded => {
    if (isModalExpanded) {
      this.disable();
    } else {
      this.enable();
    }
  };

  _createNotificationsNode = () => {
    this.notificationRoot = document.createElement('DIV');
    this.notificationRoot.className = `Global_Notifications ${this.brand}`;
    this.notificationRoot.setAttribute('data-gn', 'nohide');
    document.body.insertBefore(this.notificationRoot, document.body.childNodes[0]);
  };

  hide = () => {
    this.displayed = false;
    this.hasBeenDismissed = false;
    this.hasRedisplayInterval = false;
    this.renderNotificationComponent({ notification: this.activeNotification, open: false });
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(this.notificationRoot);
    }, animationDelayMS);
  };

  display = notification => {
    if (!this.displayed && this.enabled) {
      if ((this.hasBeenDismissed && this.hasRedisplayInterval) || !this.hasBeenDismissed) {
        this.hasBeenDismissed = false;
        this.hasRedisplayInterval = false;
        this.activeNotification = notification;
        this.displayed = true;
        this.renderNotificationComponent({ notification, open: false });
        setTimeout(() => this.renderNotificationComponent({ notification, open: true }), animationDelayMS);
      }
    }
  };

  dismiss = () => {
    handleAnalyticsEvent({ eventType: DISMISS, notification: this.activeNotification });
    this.hide();
    this.hasBeenDismissed = true;
    this.hasRedisplayInterval = !isNullEmptyOrUndefined(this.activeNotification.redisplayInterval);
    this.stateManager.setRedisplayTimer(this.activeNotification.redisplayInterval);
  };

  redirectEvent = () => {
    handleAnalyticsEvent({ eventType: REDIRECT, notification: this.activeNotification });
  };

  renderNotificationComponent = props => {
    ReactDOM.render(
      <Notification
        notification={props.notification}
        open={props.open}
        dismiss={this.dismiss}
        redirectEvent={this.redirectEvent}
      />,
      this.notificationRoot
    );
  };
}

window['globalNotifications'] = new GlobalNotifications();
