import axios from 'axios';
import tryFetchFromStorage from '../utils/sessionStorage';
import { utoa } from '../utils/defUtils';

export default class ContentService {
  constructor(apiPath, storageKey) {
    this.apiPath = apiPath;
    this.storageKey = storageKey;
  }

  async _request() {
    const storage = tryFetchFromStorage(this.storageKey);

    if (storage === null) {
      const path = this.apiPath;
      const response = await axios.get(path);
      sessionStorage.setItem(this.storageKey, utoa(JSON.stringify(response.data)));
      return response.data;
    } else {
      return Promise.resolve(storage);
    }
  }
  /**
   * Executes requests
   */
  async execute() {
    const data = await this._request();
    return data;
  }
}
