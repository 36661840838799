import NotificationsService from '../services/notificationService';
import TokenService from '../services/tokenService';
import { isNullEmptyOrUndefined } from '../utils/defUtils';
import MS_IN_SEC from '../constants/timerConstants';

export default class NotificationStore {
  constructor(token, tokenServiceUrl, alertsServiceUrl) {
    if(tokenServiceUrl) {
      this._tokenService = new TokenService(tokenServiceUrl);
    }
    this.token = token;
    this._notificationsService = new NotificationsService(alertsServiceUrl);
    this.notificationList = [];
    this.retriesArr = [1 * MS_IN_SEC, 2 * MS_IN_SEC, 5 * MS_IN_SEC];
    this.shouldRetryOnError = true;
  }
  async triggerNotificationListRefresh() {
    await this.fetchToken();
    const response = await this.fetchData();

    this.notificationList = response.data.sort((a, b) => {
      return a.start - b.start;
    });
  }

  async fetchToken() {
    if (isNullEmptyOrUndefined(this.token)) {
      this.token = await this._tokenService.execute();
    }
  }

  async fetchData() {
    let response = await this._notificationsService.execute(this.token);

    if (isNullEmptyOrUndefined(response.data) || !isNullEmptyOrUndefined(response.data.error)) {
      if (response.status >= 500) {
        response = await this.handle5XX();
      } else {
        response = await this.handle4XX();
      }
    } else {
      this.shouldRetryOnError = true;
      this.retriesArr = [1 * MS_IN_SEC, 2 * MS_IN_SEC, 5 * MS_IN_SEC];
    }

    return response;
  }

  async handle4XX() {
    let { data } = { error: 'cannot fetch notifications data' };
    if (this.shouldRetryOnError) {
      this.shouldRetryOnError = false;
      if(this._tokenService) {
        this.token = await this._tokenService.execute();
      }
      data = await this.fetchData();
    }
    return data;
  }

  async handle5XX() {
    let { data } = { error: 'cannot fetch notifications data' };
    if (this.shouldRetryOnError && this.retriesArr.length > 0) {
      const timer = this.retriesArr.shift();
      await new Promise(resolve => setTimeout(resolve, timer));
      data = this.fetchData();
    }
    return data;
  }
}
