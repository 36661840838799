// -----event name strings------//
export const DISMISS = 'Dismiss';
export const DISPLAY = 'Display';
export const REDIRECT = 'Redirect';
// -------------------------------//

// -----Analytics strings------//
export const ANALYTICS_CLICK = 'alertNotificationClick'; // specified in analytics tech doc
export const ANALYTICS_ALERT = 'onlineScheduleAlertNotification'; // specified in analytics tech doc
// -------------------------------//
