import tryFetchFromStorage from '../utils/sessionStorage';
import ContentStore from '../stores/contentStore';
import NotificationsStore from '../stores/notificationsStore';
import { isNullEmptyOrUndefined } from '../utils/defUtils';
import TIMINGS from '../constants/timerConstants';
import { AEM_CONTENT_PRE_EXTENSION, AEM_CONTENT_POST_EXTENSION, SERVICE_EXTENSION } from '../constants/routes';
import { utoa } from '../utils/defUtils';

const TIME_FORMAT_OBJECT = {
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: 'short'
};

const STRING_TO_REPLACE = 'dynamic_data';

export default class StateManager {
  constructor(display, hide, token, tokenServiceUrl, contentBaseUrl, serviceBaseUrl, language) {
    this.display = display;
    this.hide = hide;
    const contentUrl = `${contentBaseUrl}${AEM_CONTENT_PRE_EXTENSION}${language}${AEM_CONTENT_POST_EXTENSION}`;
    const alertServiceUrl = `${serviceBaseUrl}${SERVICE_EXTENSION}`;
    this.contentStore = new ContentStore(contentUrl);
    this.notificationsStore = new NotificationsStore(token, tokenServiceUrl, alertServiceUrl);
  }
  nextRefreshKey = 'OgnGnNextRefreshKey';
  _timeOuts = { checkForCancellation: {}, closeNotification: {} };
  setRedisplayTimer = interval => {
    this.clearTimeouts();
    this._timeOuts.checkForCancellation = setTimeout(this.refreshNotifications, interval);
  };

  initialize = () => {
    const nextRefreshTime = tryFetchFromStorage(this.nextRefreshKey);
    if (isNullEmptyOrUndefined(nextRefreshTime) || nextRefreshTime <= Date.now()) {
      this.refreshNotifications();
    } else {
      const delayUntilNextRefresh = nextRefreshTime - Date.now();
      setTimeout(this.refreshNotifications, delayUntilNextRefresh);
    }
  };

  refreshNotifications = async () => {
    this.clearTimeouts();
    return this.notificationsStore.triggerNotificationListRefresh().then(() => {
      const activeNotification = this.notificationsStore.notificationList[0];
      if (!isNullEmptyOrUndefined(activeNotification)) {
        const startTime = activeNotification.start;
        this.shouldNotificationDisplay(startTime)
          ? this.displayNotification(activeNotification)
          : this.delayRefreshNotifications(startTime);
      } else {
        this.delayRefreshNotifications(Date.now() + TIMINGS.DORMANT_REFRESH_MS);
      }
    });
  };

  shouldNotificationDisplay = startTime => {
    const currentTime = /*process?.env.envtName === 'mock' ? startTime + 1 :*/ Date.now();
    return currentTime >= startTime;
  };

  delayRefreshNotifications = startTime => {
    const nextUpdateTime = Math.min(startTime - Date.now(), TIMINGS.DORMANT_REFRESH_MS);
    sessionStorage.setItem(this.nextRefreshKey, utoa(JSON.stringify(nextUpdateTime)));
    setTimeout(this.refreshNotifications, nextUpdateTime);
  };

  displayNotification = async activeNotification => {
    return this.contentStore.triggerContentListRefresh().then(() => {
      const currentTime = Date.now();
      const matchedContent = this.findContentMatch(activeNotification);
      const notificationWithContent = { ...matchedContent, ...activeNotification };
      if (!isNullEmptyOrUndefined(matchedContent)) {
        const populatedNotification = this.injectTimeString(notificationWithContent);
        this.display(populatedNotification);
        this._timeOuts.closeNotification = setTimeout(() => this.hide(), activeNotification.end - currentTime);
      }
      this._timeOuts.checkForCancellation = setTimeout(
        this.refreshNotifications,
        Math.min(activeNotification.end - currentTime, TIMINGS.ACTIVE_REFRESH_MS)
      );
    });
  };

  findContentMatch = notification => {
    return this.contentStore.contentList.find(element => {
      return element.notificationId === notification.alertDefinitionId; // alertDefinitionId matches key in response from alert service
    });
  };

  injectTimeString = notification => {
    const apptTimeObj = new Date(notification.context.appointmentDateTimeISO);
    let timeString = Intl.DateTimeFormat(undefined, TIME_FORMAT_OBJECT).format(apptTimeObj);
    timeString = timeString.replace('AM', 'a.m.');
    timeString = timeString.replace('PM', 'p.m.');
    notification.subtitle = notification.subtitle.replace(STRING_TO_REPLACE, timeString);
    return notification;
  };

  clearTimeouts = () => {
    clearTimeout(this._timeOuts.closeNotification);
    clearTimeout(this._timeOuts.checkForCancellation);
  };
}
